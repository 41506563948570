<template>
  <div class="container page__inner">
    <div
      class="d-flex align-items-center justify-content-center p5"
      v-if="isLoading"
      style="width: 100%"
    >
      <div
        class="spinner-border"
        style="width: 3rem; height: 3rem"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>
      <span class="text--black text--600 text--medium d-inline-flex ml-4"
        >Please wait</span
      >
    </div>
    <GroupAside
      v-if="!isLoading"
      v-bind:group-name="groupInfo.groupName"
      v-bind:group-key="groupInfo.groupKey"
      :groupLoanDeposit="customerLoanDeposits"
      v-bind:switch-tab-fn-prop="switchTab"
      :accountKey="null"
      @imageAlert="imageAlert"
    ></GroupAside>
    <div
      class="page__content2"
      v-if="!isLoading && currentTab == 'OverViewTab'"
    >
      <div class="page__head d-flex">
        <div
          class="page__head__container ml-auto align-items-center justify-content-between"
        >
          <div class="page__head__actions">
            <div class="d-flex">
              <button
                type="button"
                class="mx-2 button page__body__header__button button--grey text--capital"
                data-target="#newTaskModal"
                data-toggle="modal"
                v-if="userCanCreateTask"
              >
                <span class="d-inline-flex mr-2">new task</span>
              </button>
              <div class="align-self-center mx-2 dropdown">
                <button
                  class="button button--grey text--capital text--small d-flex align-items-center"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  new account
                  <span class="dropdown__icon">
                    <ion-icon name="caret-down-outline"></ion-icon>
                  </span>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <!-- <a
                    class="dropdown-item text--capital"
                    @click="openCreateLoanAccountModal"
                    >new loan account</a
                  > -->
                  <a
                    class="dropdown-item text--capital"
                    @click="openCreateAccountModal"
                    v-if="userCanCreateDeposit"
                    >new deposit account</a
                  >
                </div>
              </div>
              <button
                type="button"
                @click="openEditModal"
                class="mx-2 button page__body__header__button button--grey text--capital"
                v-if="userCanEditGroup"
              >
                <span class="d-inline-flex mr-2">edit</span>
              </button>
              <div class="align-self-center mx-2 dropdown">
                <button
                  class="button button--grey text--capital text--small d-flex align-items-center"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  more
                  <span class="dropdown__icon">
                    <ion-icon name="caret-down-outline"></ion-icon>
                  </span>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a
                    class="dropdown-item text--capital"
                    @click="openAddFieldModal"
                    v-if="userCanCreateGroup"
                    >Add Field</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="page__body">
        <div>
          <div class="page__body__header">
            <ul
              class="nav page__tabs page__tabs2"
              id="page-Tabs"
              role="tablist"
            >
              <li class="nav-item">
                <a
                  class="nav-link active text--capital"
                  id="pills-details-tab"
                  data-toggle="tab"
                  href="#pills-details"
                  role="tab"
                  aria-controls="pills-details"
                  aria-selected="true"
                  >details</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link text--capital"
                  id="pills-activities-tab"
                  data-toggle="tab"
                  href="#pills-activities"
                  role="tab"
                  aria-controls="pills-activities"
                  aria-selected="false"
                  >activities</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link text--capital"
                  id="pills-attachments-tab"
                  data-toggle="tab"
                  href="#pills-attachments"
                  role="tab"
                  aria-controls="pills-attachments"
                  aria-selected="false"
                  >attachments</a
                >
              </li>
              <li class="nav-item" v-if="userCanViewComment">
                <a
                  class="nav-link text--capital"
                  id="pills-comments-tab"
                  data-toggle="tab"
                  href="#pills-comments"
                  role="tab"
                  aria-controls="pills-comments"
                  aria-selected="false"
                  >comments</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="tab-content" id="page-TabsContent">
        <div
          class="tab-pane fade show active"
          id="pills-details"
          role="tabpanel"
          aria-labelledby="pills-details-tab"
        >
          <div class="page__body">
            <div class="page__body__header">
              <div
                class="page__head__container align-items-center justify-content-between"
              >
                <div class="page__head__text">
                  <h1 class="page__head__title">Group Details</h1>
                </div>
                <!-- <div class="page__head__actions">
                  <div class="d-flex">
                    <button
                      data-target="#viewNotificationModal"
                      data-toggle="modal"
                      type="button"
                      class="
                        mx-2
                        button
                        page__body__header__button
                        button--primary
                        text--capital
                      "
                    >
                      <img
                        src="../../../../assets/img/notification-filled.svg"
                        alt=""
                        srcset=""
                      />
                      <span class="d-inline-flex ml-2">Notfications</span>
                    </button>
                    <button
                      data-target="#latestActivitiesModal"
                      data-toggle="modal"
                      type="button"
                      class="
                        mx-2
                        button
                        page__body__header__button
                        button--success
                        text--capital
                      "
                    >
                      <img
                        src="../../../../assets/img/activity.svg"
                        alt=""
                        srcset=""
                      />
                      <span class="d-inline-flex ml-2">Activities</span>
                    </button>
                  </div>
                </div> -->
              </div>
            </div>

            <div>
              <div class="row mt5">
                <div class="col-6">
                  <div class="account_item_head">
                    <p class="account_item_head_text text--white">general</p>
                  </div>
                  <div class="row no-gutters account_item align-items-center">
                    <div class="col-6">
                      <span
                        class="text--black text--opaq2 text--small text--bold account_item_title"
                      >
                        ID
                      </span>
                      <span
                        class="text--black text--regular account_item_subtitle"
                      >
                        {{ groupInfo.uniqueGroupId }}
                      </span>
                    </div>
                    <div class="col-6">
                      <span
                        class="text--black text--opaq2 text--small text--bold account_item_title"
                      >
                        Group Email
                      </span>
                      <span
                        class="text--black text--regular account_item_subtitle"
                      >
                        {{ groupInfo.groupEmailAddress }}
                      </span>
                    </div>
                  </div>
                  <div class="row no-gutters account_item align-items-center">
                    <div class="col-6">
                      <span
                        class="text--black text--opaq2 text--small text--bold account_item_title"
                      >
                        Created
                      </span>
                      <span
                        class="text--black text--regular account_item_subtitle"
                      >
                        {{ groupInfo.creationDate | formatDatePipe }}
                      </span>
                    </div>
                    <div class="col-6">
                      <span
                        class="text--black text--opaq2 text--small text--bold account_item_title"
                      >
                        Last Modified
                      </span>
                      <span
                        class="text--black text--regular account_item_subtitle"
                      >
                        {{ groupInfo.lastModifiedDate | formatDatePipe }}
                      </span>
                    </div>
                  </div>
                  <div class="row no-gutters account_item align-items-center">
                    <div class="col-6">
                      <span
                        class="text--black text--opaq2 text--small text--bold account_item_title"
                      >
                        Assigned to Branch
                      </span>
                      <span
                        class="text--black text--regular account_item_subtitle"
                      >
                        {{ groupInfo.branchName }}
                      </span>
                    </div>
                    <div class="col-6">
                      <span
                        class="text--black text--opaq2 text--small text--bold account_item_title"
                      >
                        Assigned to Centre
                      </span>
                      <span
                        class="text--black text--regular account_item_subtitle"
                      >
                        {{ groupInfo.centreName }}
                      </span>
                    </div>
                  </div>
                  <div class="row no-gutters account_item align-items-center">
                    <div class="col-6">
                      <span
                        class="text--black text--opaq2 text--small text--bold account_item_title"
                      >
                        Assigned to Account Officer
                      </span>
                      <span
                        class="text--black text--regular account_item_subtitle"
                      >
                        {{ groupInfo.userName }}
                      </span>
                    </div>
                    <div class="col-6">
                      <span
                        class="text--black text--opaq2 text--small text--bold account_item_title"
                      >
                        Group state
                      </span>
                      <span
                        class="text--black text--regular account_item_subtitle"
                      >
                        {{ groupInfo.groupState ? groupInfo.groupState : "" }}
                      </span>
                    </div>
                  </div>
                  <div class="row no-gutters account_item align-items-center">
                    <div class="col-6">
                      <span
                        class="text--black text--opaq2 text--small text--bold account_item_title"
                      >
                        Approved Date
                      </span>
                      <span
                        class="text--black text--regular account_item_subtitle"
                      ></span>
                    </div>
                    <div class="col-6">
                      <span
                        class="text--black text--opaq2 text--small text--bold account_item_title"
                      >
                        Activated Date
                      </span>
                      <span
                        class="text--black text--regular account_item_subtitle"
                      ></span>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="account_item_head">
                    <p class="account_item_head_text text--white">
                      Contact Information
                    </p>
                  </div>
                  <div class="row account_item align-items-center">
                    <div class="col-6">
                      <span
                        class="text--black text--opaq2 text--small text--bold account_item_title"
                      >
                        Group Name
                      </span>
                      <span
                        class="text--black text--regular account_item_subtitle"
                      >
                        {{ groupInfo.groupName }}
                      </span>
                    </div>
                    <div class="col-6">
                      <span
                        class="text--black text--opaq2 text--small text--bold account_item_title"
                      >
                        Phone Number
                      </span>
                      <span
                        class="text--black text--regular account_item_subtitle"
                      >
                        {{ groupInfo.groupPhoneNumber }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="account_item_head">
                    <p class="account_item_head_text text--white">Members</p>
                  </div>
                  <div class="row no-gutters account_item align-items-center">
                    <div
                      v-for="customer in groupInfo.groupMembersRoles"
                      v-bind:key="customer.clientKey"
                      class="col-6 no-gutters account_item align-items-center"
                    >
                      <span
                        class="text--black text--opaq2 text--small text--bold account_item_title"
                      >
                        Name
                      </span>
                      <span
                        class="text--black text--regular text--upper account_item_subtitle"
                      >
                        {{ customer.customerName }}
                      </span>
                    </div>
                  </div>
                </div>
                <!-- Custom Fields -->
                <div
                  class="col-6"
                  v-for="fieldset in groupInfo.customFieldSetFieldsAndValue"
                  v-bind:key="fieldset.fieldSetKey"
                  v-show="userCanViewGroup"
                >
                  <div class="account_item_head">
                    <p class="account_item_head_text text--white">
                      {{ fieldset.fieldSetName }}
                    </p>
                  </div>
                  <div class="row no-gutters account_item align-items-start">
                    <div
                      class="col-6 p-4"
                      v-for="field in fieldset.fields"
                      v-bind:key="field.fieldKey"
                    >
                      <div
                        class="text--black text--opaq2 text--small text--bold account_item_title"
                        style="width: 100%"
                      >
                        <span style="word-break: break-word">{{
                          field.fieldName
                        }}</span>
                        {{ " " }}
                        <a
                          class="text--primary text--capital account_item_head_text mr-5"
                          @click="
                            editCustomField(
                              fieldset.fieldSetKey,
                              field.fieldKey,
                              field.value,
                              field.isDefault,
                              field.isRequired,
                              fieldset.fields,
                              true
                            )
                          "
                          v-if="userCanEditGroup"
                          >edit</a
                        >
                      </div>
                      <span
                        v-if="
                          ['customer link', 'group link', 'user link'].includes(
                            field.dataType.toLowerCase()
                          )
                        "
                        class="table__body__text table__text text--capital text--primary cursor account_item_subtitle"
                        style="cursor: pointer; word-wrap: break-word"
                        @click="routeToLinkView(field.value, field.dataType)"
                      >
                        {{
                          field.value && field.value.length
                            ? field.value.split("-").at(-1)
                            : ""
                        }}
                      </span>
                      <span
                        v-else
                        class="text--black text--regular account_item_subtitle"
                        style="word-wrap: break-word"
                      >
                        {{ field.value }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="tab-pane fade"
          id="pills-activities"
          role="tabpanel"
          aria-labelledby="pills-activities-tab"
        >
          <div class="pt-3">
            <div
              class="form__item"
              v-for="activity in allActivities"
              :key="activity.activity_key"
            >
              <div class="row">
                <div class="col-1">
                  <img
                    src="../../../../assets/img/edit-icon.svg"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="col-11">
                  <p>
                    <span
                      class="d-inline-block text--regular text--dark text--opaq1 text-300"
                      >{{ activity.activity_notes }}</span
                    >
                  </p>
                  <span class="text--tiny text--dark text-300 text--opaq1">
                    {{ activity.creation_date | formatDatePipe }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="tab-pane fade"
          id="pills-attachments"
          role="tabpanel"
          aria-labelledby="pills-attachments-tab"
        >
          <div class="mb-3" v-if="userCanUploadAttachment">
            <button
              class="button button--black d-flex align-items-center ml-auto"
              @click="openAttachmentModal"
            >
              <span
                class="text--capital d-inline-flex mr-2 text-white text--small"
                >upload new document</span
              >
              <img src="../../../../assets/img/upload.svg" alt="" srcset="" />
            </button>
          </div>

          <table class="customtable w-100">
            <thead>
              <tr>
                <th
                  scope="col"
                  class="table__header__text table__text text--capital"
                  style="width: 20%"
                >
                  Document
                </th>
                <th
                  scope="col"
                  class="table__header__text table__text text--capital"
                  style="width: 15%"
                >
                  File Type
                </th>
                <th
                  scope="col"
                  class="table__header__text table__text text--capital"
                  style="width: 15%"
                >
                  Size
                </th>
                <th
                  scope="col"
                  class="table__header__text table__text text--capital"
                  style="width: 15%"
                >
                  Last Modified
                </th>
                <th
                  scope="col"
                  class="table__header__text table__text text--capital"
                  style="width: 20%"
                >
                  Added By
                </th>
                <th
                  scope="col"
                  class="table__header__text table__text text--capital"
                  style="width: 15%"
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody v-if="!this.attachments.data">
              <tr>
                <td colspan="12">
                  <div
                    class="d-flex align-items-center justify-content-center p5"
                  >
                    <div
                      class="spinner-border"
                      style="width: 3rem; height: 3rem"
                      role="status"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                    <span
                      class="text--black text--600 text--medium d-inline-flex ml-4"
                      >Please wait</span
                    >
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-else-if="this.attachments.data != ''">
              <tr v-for="(attach, i) in this.attachments.data" :key="i">
                <td>
                  <span
                    class="table__body__text table__text text--capital d-inline-flex align-items-center"
                  >
                    <img
                      src="../../../../assets/img/paper.svg"
                      alt=""
                      srcset=""
                      class="mr-2"
                    />
                    {{ attach.document_title }}
                  </span>
                </td>
                <td>
                  <span class="table__body__text table__text text--capital">{{
                    attach.document_type
                  }}</span>
                </td>
                <td>
                  <span class="table__body__text table__text text--capital">{{
                    attach.document_file_size | kb
                  }}</span>
                </td>
                <td>
                  <span class="table__body__text table__text text--capital">{{
                    attach.creation_date | formatDatePipe
                  }}</span>
                </td>
                <td>
                  <span class="table__body__text table__text text--capital">{{
                    attach.created_by
                  }}</span>
                </td>
                <td>
                  <div class="align-self-center mx-2 dropdown">
                    <button
                      class="button button--grey text--capital text--small d-flex align-items-center"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      action
                      <span class="dropdown__icon">
                        <ion-icon name="caret-down-outline"></ion-icon>
                      </span>
                    </button>
                    <div
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a
                        class="dropdown-item text--capital"
                        @click="previewFile(attach.document_key)"
                        >Preview</a
                      >
                      <a
                        class="dropdown-item text--capital"
                        @click="downloadFile(attach.document_key)"
                        >Download</a
                      >
                      <!-- <a class="dropdown-item text--capital">Edit</a> -->
                      <a
                        class="dropdown-item text--capital"
                        @click="openDeleteAttachmentModal(attach.document_key)"
                        v-if="userCanEditAttachment"
                        >Delete</a
                      >
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="12">
                  <div
                    class="d-flex flex-column align-items-center justify-content-center p5"
                  >
                    <img src="@/assets/img/empty.svg" alt="delete" srcset="" />

                    <span
                      class="d-block text--danger text--600 text--medium mt-3 mb-5"
                      >No data available</span
                    >
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          class="tab-pane fade"
          id="pills-comments"
          role="tabpanel"
          aria-labelledby="pills-comments-tab"
        >
          <div class="mb-3">
            <button
              class="button button--black d-flex align-items-center ml-auto"
              data-modal="#addCommentModal"
              v-if="userCanCreateComment"
              @click="openCommentModal"
            >
              <span
                class="text--capital d-inline-flex mr-2 text-white text--small"
                >Add new Comments</span
              >
              <ion-icon
                name="add-outline"
                class="text--600 text--medium text-white"
              ></ion-icon>
            </button>
          </div>
          <div>
            <div
              class="form__item"
              v-for="comment in comments"
              :key="comment.commentKey"
            >
              <div class="row">
                <div class="col-3">
                  <div class="row no-gutters">
                    <div class="col-1">
                      <img
                        src="../../../../assets/img/comment-green.svg"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div class="col-11 px-5">
                      <span
                        class="d-block text--black text--regular text--600"
                        >{{ comment.createdBy }}</span
                      >
                      <span class="text--tiny text--dark text-300 text--opaq1">
                        {{ comment.creationDate | formatDatePipe }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-5">
                  <div class="pr-5">
                    <p class="text--large" style="font-size: 14px !important">
                      {{ comment.commentNote }}
                    </p>
                  </div>
                </div>
                <div class="col-4 align-self-center">
                  <div
                    class="pl-5 d-flex align-items-center justify-content-end"
                  >
                    <button
                      type="button"
                      class="mx-2 button button--grey text--capital"
                      v-if="userCanEditComment"
                      @click="openEditCommentModal(comment)"
                    >
                      <span class="d-inline-flex">edit</span>
                    </button>
                    <button
                      type="button"
                      class="mx-2 button button--cancel text--capital"
                      v-if="userCanDeleteComment"
                      @click="openDeleteModal(comment)"
                    >
                      <span class="d-inline-flex">delete</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <TaskView
      v-if="
        !isLoading && groupInfo.groupKey != null && currentTab == 'TaskViewTab'
      "
      v-bind:task-link-key-prop="groupInfo.groupKey"
      task-link-type-prop="GROUP"
    ></TaskView>

    <div
      class="modal modal__right"
      id="latestActivitiesModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a data-dismiss="modal" class="modal__close modal__close--black">
                <ion-icon name="arrow-back-outline"></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">Latest Activities</h5>
          </div>
          <div class="modal__body">
            <form>
              <div>
                <div class="form__item">
                  <div class="row">
                    <div class="col-1">
                      <img
                        src="../../../../assets/img/login.svg"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div class="col-11">
                      <p>
                        <span
                          class="d-inline-block text--black text--regular-alt text--600"
                          >Babatunde Olofin</span
                        >
                        &nbsp;<span
                          class="d-inline-block text--regular text--dark text--opaq1 text--capital text-300"
                          >Logged In</span
                        >
                      </p>
                      <span class="text--tiny text--dark text-300 text--opaq1">
                        20 mins ago
                      </span>
                    </div>
                  </div>
                </div>

                <div class="form__item">
                  <div class="row">
                    <div class="col-1">
                      <img
                        src="../../../../assets/img/edit-icon.svg"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div class="col-11">
                      <p>
                        <span
                          class="d-inline-block text--black text--regular-alt text--600"
                          >Babatunde Olofin</span
                        >&nbsp;
                        <span
                          class="d-inline-block text--regular text--dark text--opaq1 text--capital text-300"
                          >edited profile of</span
                        >&nbsp;
                        <span
                          class="d-inline-block text--black text--regular-alt text--600"
                          >Jerryson</span
                        >
                      </p>
                      <p>
                        <span
                          class="d-inline-block text--regular text--dark text--opaq1 text-300"
                          >Changed user state from</span
                        >&nbsp;<span
                          class="d-inline-block text--regular text--success text--opaq0 ext--capital text-300"
                          >‘Active’</span
                        >&nbsp;
                        <span
                          class="d-inline-block text--regular text--dark text--opaq1 text-300"
                          >to</span
                        >&nbsp;<span
                          class="d-inline-block text--regular text--danger text--capital text-300"
                          >Inactive</span
                        >
                      </p>
                      <span class="text--tiny text--dark text-300 text--opaq1">
                        20 mins ago
                      </span>
                    </div>
                  </div>
                </div>
                <div class="form__item">
                  <div class="row">
                    <div class="col-1">
                      <img
                        src="../../../../assets/img/edit-icon.svg"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div class="col-11">
                      <p>
                        <span
                          class="d-inline-block text--black text--regular-alt text--600"
                          >Babatunde Olofin</span
                        >&nbsp;
                        <span
                          class="d-inline-block text--regular text--dark text--opaq1 text-300"
                          >edited profile of</span
                        >&nbsp;
                        <span
                          class="d-inline-block text--black text--regular-alt text--600"
                          >Jerryson</span
                        >
                      </p>
                      <p>
                        <span
                          class="d-inline-block text--regular text--dark text--opaq1 text-300"
                          >Changed user state from</span
                        >&nbsp;<span
                          class="d-inline-block text--regular text--success text--opaq0 ext--capital text-300"
                          >‘Active’</span
                        >&nbsp;
                        <span
                          class="d-inline-block text--regular text--dark text--opaq1 text-300"
                          >to</span
                        >&nbsp;<span
                          class="d-inline-block text--regular text--danger text--capital text-300"
                          >Inactive</span
                        >
                      </p>
                      <span class="text--tiny text--dark text-300 text--opaq1">
                        20 mins ago
                      </span>
                    </div>
                  </div>
                </div>

                <div class="form__item">
                  <div class="row">
                    <div class="col-1">
                      <img
                        src="../../../../assets/img/login.svg"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div class="col-11">
                      <p>
                        <span
                          class="d-inline-block text--black text--regular-alt text--600"
                          >Babatunde Olofin</span
                        >
                        &nbsp;<span
                          class="d-inline-block text--regular text--dark text--opaq1 text--capital text-300"
                          >Logged In</span
                        >
                      </p>
                      <span class="text--tiny text--dark text-300 text--opaq1">
                        20 mins ago
                      </span>
                    </div>
                  </div>
                </div>
                <div class="form__item">
                  <div class="row">
                    <div class="col-1">
                      <img
                        src="../../../../assets/img/login.svg"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div class="col-11">
                      <p>
                        <span
                          class="d-inline-block text--black text--regular-alt text--600"
                          >Babatunde Olofin</span
                        >
                        &nbsp;<span
                          class="d-inline-block text--regular text--dark text--opaq1 text--capital text-300"
                          >Logged In</span
                        >
                      </p>
                      <span class="text--tiny text--dark text-300 text--opaq1">
                        20 mins ago
                      </span>
                    </div>
                  </div>
                </div>
                <div class="form__item">
                  <div class="row">
                    <div class="col-1">
                      <img
                        src="../../../../assets/img/login.svg"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div class="col-11">
                      <p>
                        <span
                          class="d-inline-block text--black text--regular-alt text--600"
                          >Babatunde Olofin</span
                        >
                        &nbsp;<span
                          class="d-inline-block text--regular text--dark text--opaq1 text--capital text-300"
                          >Logged In</span
                        >
                      </p>
                      <span class="text--tiny text--dark text-300 text--opaq1">
                        20 mins ago
                      </span>
                    </div>
                  </div>
                </div>
                <div class="form__item">
                  <div class="row">
                    <div class="col-1">
                      <img
                        src="../../../../assets/img/comment.svg"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div class="col-11">
                      <p>
                        <span
                          class="d-inline-block text--black text--regular-alt text--600"
                          >Babatunde Olofin</span
                        >&nbsp;
                        <span
                          class="d-inline-block text--regular text--dark text--opaq1 text-300"
                          >wrote a comment on</span
                        >&nbsp;
                        <span
                          class="d-inline-block text--black text--regular-alt text--600"
                        >
                          payroll</span
                        >
                      </p>
                      <p>
                        <span
                          class="d-inline-block text--regular text--dark text--opaq1 text-300"
                          >Thanks for the feedback
                        </span>
                      </p>
                      <span class="text--tiny text--dark text-300 text--opaq1">
                        20 mins ago
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal modal__right"
      id="viewNotificationModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a data-dismiss="modal" class="modal__close modal__close--black">
                <ion-icon name="arrow-back-outline"></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">Notifications</h5>
          </div>
          <div class="modal__body">
            <form>
              <div class="mb5">
                <div class="form__item">
                  <input
                    type="text"
                    class="input input--right form__input form__input--lg"
                    value=""
                    placeholder="Search for any notification you want"
                  />
                </div>
              </div>
              <div class="mb-5">
                <div class="row no-gutters align-items-center">
                  <div class="col-5 py-5 border-bottom">
                    <span
                      class="text--black text--opaq2 text--small d-block text--bold text--capital"
                    >
                      Loan Account Rejection
                    </span>
                    <span
                      class="text--black text--regular d-block text--capital"
                    >
                      Email (Disabled)
                    </span>
                  </div>
                </div>
                <div class="row no-gutters align-items-center">
                  <div class="col-5 py-5 border-bottom">
                    <span
                      class="text--black text--opaq2 text--small d-block text--bold text--capital"
                    >
                      Loan Account Refinanced
                    </span>
                    <span
                      class="text--black text--regular d-block text--capital"
                    >
                      Web Hook (Disabled)
                    </span>
                  </div>
                </div>
                <div class="row no-gutters align-items-center">
                  <div class="col-5 py-5 border-bottom">
                    <span
                      class="text--black text--opaq2 text--small d-block text--bold text--capital"
                    >
                      Deposit Account Created
                    </span>
                    <span
                      class="text--black text--regular d-block text--capital"
                    >
                      Web Hook
                    </span>
                  </div>
                </div>
                <div class="row no-gutters align-items-center">
                  <div class="col-5 py-5 border-bottom">
                    <span
                      class="text--black text--opaq2 text--small d-block text--bold text--capital"
                    >
                      Account In Arrears
                    </span>
                    <span
                      class="text--black text--regular d-block text--capital"
                    >
                      SMS (Disabled)
                    </span>
                  </div>
                </div>
                <div class="row no-gutters align-items-center">
                  <div class="col-5 py-5 border-bottom">
                    <span
                      class="text--black text--opaq2 text--small d-block text--bold text--capital"
                    >
                      Loan Disbursement
                    </span>
                    <span
                      class="text--black text--regular d-block text--capital"
                    >
                      Web Hook
                    </span>
                  </div>
                </div>
                <div class="row no-gutters align-items-center">
                  <div class="col-5 py-5">
                    <span
                      class="text--black text--opaq2 text--small d-block text--bold text--capital"
                    >
                      Loan Account Created
                    </span>
                    <span
                      class="text--black text--regular d-block text--capital"
                    >
                      SMS (Disabled)
                    </span>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div v-if="userIsUnauthorized" style="width: 100%">
      <h4 class="text-center mt-5 text-danger">{{ error }}</h4>
    </div>

    <DeleteModal
      :show="userIsUnauthorized"
      :title="'Unauthorized'"
      @close="goToDashboard"
    >
      <template v-slot:modal-body>
        <div class="modal__content">
          <div class="modal__body">
            <h5 class="modal__head__title text--capital mb-3">Unauthorized</h5>
            <h5 class="text-danger">
              {{ groupError }}
            </h5>
            <div class="d-flex mt-5">
              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--lg save-changes fitrem"
                @click.prevent="goToDashboard"
              >
                Go to Dashboard
              </button>
            </div>
          </div>
        </div>
      </template>
    </DeleteModal>

    <!-- Modals -->
    <div
      class="modal modal__right"
      id="newTaskModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon" v-show="!isSaving">
              <a
                data-dismiss="modal"
                id="newTaskModalClose"
                class="modal__close modal__close--black"
              >
                <ion-icon name="arrow-back-outline"></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">Add a Task</h5>
          </div>
          <div class="modal__body">
            <form class="form" @submit="saveTaskForm">
              <div class="form-row">
                <div class="col-md-6 form__item">
                  <div class="form-group">
                    <label class="form__label">Summary</label>
                    <input
                      class="input form__input w-100"
                      type="text"
                      v-model="taskForm.taskTitle"
                      v-bind:class="{
                        'input-required': taskFormErr.taskTitle,
                      }"
                    />
                  </div>
                </div>
                <div class="col-md-6 form__item d-none">
                  <div class="form-group">
                    <label class="form__label">Unique ID</label>
                    <input
                      class="input form__input w-100"
                      type="text"
                      v-model="taskForm.uniqueTaskId"
                      v-bind:class="{
                        'input-required': taskFormErr.uniqueTaskId,
                      }"
                    />
                  </div>
                </div>
                <div class="col-md-6 form__item">
                  <div class="form-group">
                    <label class="form__label">Linked To</label>
                    <input
                      class="input form__input w-100"
                      type="text"
                      readonly
                      :value="taskLinkName"
                    />
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-md-6 form__item">
                  <label class="form__label">Assigned To</label>
                  <multiselect
                    v-model="assignedPerson"
                    id="ajax"
                    label="name"
                    track-by="name"
                    placeholder="Find Users"
                    open-direction="bottom"
                    :options="users"
                    :custom-label="name"
                    :multiple="false"
                    :searchable="true"
                    :internal-search="true"
                    :clear-on-select="false"
                    :close-on-select="true"
                    :options-limit="300"
                    :limit="3"
                    :limit-text="limitText"
                    :max-height="800"
                    :show-no-results="false"
                    :hide-selected="true"
                    v-bind:class="{
                      'input-required': taskFormErr.assignedUserKey,
                    }"
                  >
                    <template slot="tag" slot-scope="{ option, remove }">
                      <span class="custom__tag">
                        <span>{{ option.name }}</span>
                        <span class="custom__remove" @click="remove(option)"
                          >❌</span
                        >
                      </span>
                    </template>
                  </multiselect>
                  <!--<select
                    class="select--lg w-100"
                    v-model="taskForm.assignedUserKey"
                    v-bind:class="{
                      'input-required': taskFormErr.assignedUserKey,
                    }"
                  >
                    <option disabled></option>
                  </select>-->
                </div>
                <div class="col-md-6 form__item">
                  <label class="form__label">Due Date</label>
                  <input
                    v-model="taskForm.dueDate"
                    class="input form__input w-100"
                    placeholder="Enter date"
                    type="date"
                    :min="currentDate"
                    v-bind:class="{
                      'input-required': taskFormErr.dueDate,
                    }"
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="col-md-12 form__item">
                  <label class="form__label">Notes</label>
                  <textarea
                    cols="5"
                    rows="5"
                    class="input form__input"
                    placeholder="Profile Note"
                    v-model="taskForm.taskDescription"
                  ></textarea>
                </div>
              </div>
              <div class="form__item">
                <div class="form__action">
                  <button
                    type="submit"
                    class="button form__button form__button--lg w-30 save-changes"
                    v-if="!isSaving"
                  >
                    Save Task
                  </button>

                  <a
                    role="button"
                    class="button form__button form__button--lg w-25 save-changes"
                    v-if="isSaving"
                    :disabled="true"
                  >
                    <div class="spinner-border text-light" role="status"></div>
                  </a>
                  <a
                    data-dismiss="modal"
                    role="button"
                    class="modal__close form__action__text form__action__text--danger text--capital ml6"
                    v-if="!isSaving"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!--ALERT NOTIFICATION-->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
          'toaster--warn': alert.status === 'warn',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>

    <createAccount
      v-bind:show="showCreateAccountModal"
      @close="closeCreateAccountModal"
    >
      <template v-slot:modal-head>
        <div class="modal__head__icon">
          <a
            class="modal__close modal__close--black"
            @click="closeCreateAccountModal()"
          >
            <ion-icon name="arrow-back-outline"></ion-icon>
          </a>
        </div>
      </template>
      <template v-slot:modal-body>
        <form>
          <div v-show="depositForm">
            <div class="col-12 no--padding">
              <div class="form__item">
                <label class="form__label">Select Customer or Group</label>
                <select
                  class="select select--lg"
                  required
                  v-model.trim="deposit.accountType"
                >
                  <option value="">Select customer or group</option>
                  <option value="Groups" selected>Group</option>
                </select>
              </div>
            </div>
            <div class="col-12 no--padding">
              <div class="form__item">
                <label class="form__label" for="ajax">Display Name</label>
                <div>
                  <input
                    type="text"
                    class="input form__input form__input--lg"
                    readonly
                    :value="groupInfo.groupName"
                    placeholder="Enter Display Name"
                  />
                </div>
              </div>
            </div>
            <div class="col-12 no--padding">
              <div class="form__item">
                <label class="form__label">Select Product</label>
                <select
                  class="select select--lg"
                  required
                  v-model.trim="deposit.product"
                >
                  <option value="" selected default>Select account type</option>
                  <option
                    v-bind:key="deposit.id"
                    v-for="deposit in deposits"
                    :id="deposit.id"
                    :value="deposit"
                    @input="this.getCustomerId()"
                  >
                    {{ deposit.productName }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form__item">
              <div class="form__action">
                <button
                  data-dismiss="#newDepositModal"
                  type="button"
                  class="button form__button form__button--lg w-30 save-changes"
                  @click="depositAccountFormCreate"
                >
                  continue
                </button>
                <a
                  data-dismiss="#newDepositModal"
                  role="button"
                  class="modal__close form__action__text form__action__text--danger text--capital ml6"
                  @click="closeCreateAccountModal()"
                >
                  cancel
                </a>
              </div>
            </div>
          </div>
          <div v-show="nextDepositForm">
            <div class="row">
              <div class="col-6">
                <div class="form__item">
                  <label class="form__label">Display Name</label>
                  <input
                    type="text"
                    class="input form__input form__input--lg"
                    v-model="fetchDeposit.savingsAccountName"
                    placeholder="Enter Display Name"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form__item">
                  <label class="form__label">Display ID</label>
                  <input
                    type="text"
                    class="input form__input form__input--lg"
                    v-model="deposit.product.sampleIdGenerated"
                    placeholder="Enter Display ID"
                  />
                  <span>ID Template: {{ deposit.product.idTemplate }}</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form__item">
                  <label class="form__label">Product Type</label>
                  <p class="text--black text--600 text--capital text--regular">
                    {{ deposit.product.productType }}
                  </p>
                </div>
              </div>
              <div class="col-6">
                <div class="form__item">
                  <label class="form__label">currency</label>
                  <p class="text--black text--600 text--capital text--regular">
                    {{ deposit.product.currency }}
                  </p>
                </div>
              </div>
            </div>
            <div class="form__item">
              <p class="text--black text--600 text--capital text--small">
                {{ deposit.product.productDescription }}
              </p>
            </div>

            <ul id="accordion" class="mb5">
              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#accountTerms"
                  aria-expanded="false"
                  aria-controls="accountTerms"
                >
                  <h5
                    class="w-100 mb-0 d-flex align-items-center justify-content-between"
                  >
                    Account Terms
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div
                  id="accountTerms"
                  class="collapse"
                  data-parent="#accordion"
                >
                  <div class="pt-5">
                    <div v-show="fixedTermR" class="row">
                      <div class="col-12">
                        <div class="form__item">
                          <label class="form__label">Interest Rate</label>
                          <div v-if="errors.length">
                            <div v-for="error in errors" :key="error.id">
                              <span
                                class="text--tiny d-block text--left text--600 mb-2 text--danger"
                                >{{ error }}</span
                              >
                            </div>
                          </div>
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span
                                class="w-100 d-flex text--medium text-white text--600 align-items-center justify-content-center"
                              >
                                %
                              </span>
                            </div>
                            <input
                              type="number"
                              class="input input--right form__input form__input--lg"
                              placeholder="Enter interest rate per month"
                              :min="deposit.product.minInterestRateConstraints"
                              :max="deposit.product.maxInterestRateConstraints"
                              v-model.number="fetchDeposit.interestRate"
                            />
                          </div>
                          <small
                            class="text--tiny d-block text--right text--600 mt-2"
                            >Min:
                            {{ deposit.product.minInterestRateConstraints }}
                            Max:
                            {{ deposit.product.maxInterestRateConstraints }}
                            &nbsp;&nbsp;|&nbsp;&nbsp;
                            {{ deposit.product.interestChargeMethod }}
                          </small>
                        </div>
                        <!-- <div class="error" v-if="!$v.fetchDeposit.interest.between">
                          <span class="text-danger">Deposit Product name is required</span>
                        </div> -->
                      </div>
                    </div>
                    <div class="row">
                      <div
                        class="col-6"
                        v-if="
                          deposit.product.calculationAccountBalance !== null
                        "
                      >
                        <div class="form__item">
                          <label class="form__label"
                            >Interest Calculated Using</label
                          >
                          <p
                            class="text--black text--600 text--capital text--regular"
                          >
                            {{ deposit.product.calculationAccountBalance }}
                          </p>
                        </div>
                      </div>
                      <div
                        class="col-6"
                        v-if="deposit.product.maxTermLength !== 0"
                      >
                        <div class="form__item">
                          <label class="form__label">Maximum term length</label>
                          <p
                            class="text--black text--600 text--capital text--regular"
                          >
                            {{ deposit.product.maxTermLength }}
                          </p>
                        </div>
                      </div>
                      <div
                        class="col-6"
                        v-if="deposit.product.interestPaymentPeriod !== null"
                      >
                        <div class="form__item">
                          <label class="form__label"
                            >Interest paid into account</label
                          >
                          <p
                            class="text--black text--600 text--capital text--regular"
                          >
                            {{ deposit.product.interestPaymentPeriod }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label"
                            >Maximum Withdrawal Amount</label
                          >
                          <input
                            type="text"
                            class="input input--right form__input form__input--lg"
                            min="0"
                            placeholder="Enter Maximum Withdrawal Amount"
                            v-model="fetchDeposit.maxWithdrawalAmount"
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label"
                            >Recommended Deposit Amount</label
                          >
                          <input
                            type="text"
                            class="input input--right form__input form__input--lg"
                            min="0"
                            placeholder="Enter Recommended Deposit"
                            v-model="fetchDeposit.defaultDepositAmount"
                          />
                        </div>
                      </div>
                      <div class="col-6" v-if="isSavingPlan">
                        <div class="form__item">
                          <label class="form__label">Saving Goal Target</label>
                          <input
                            type="text"
                            class="input input--right form__input form__input--lg"
                            min="0"
                            placeholder="Enter Recommended Deposit"
                            v-model.number="fetchDeposit.savingsGoalTarget"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      class="form__item"
                      v-if="deposit.product.interestRateTerms !== null"
                    >
                      <label class="form__label">Interest Rate Terms</label>
                      <p
                        class="text--black text--600 text--capital text--regular"
                      >
                        {{ deposit.product.interestRateTerms }}
                      </p>
                    </div>

                    <div v-show="tierBalance" class="row">
                      <div class="col-12">
                        <div
                          class="form__item p-5 border border-radius"
                          v-for="(balanceTier, counter) in deposit.product
                            .tiers"
                          :key="counter"
                        >
                          <p
                            class="text--primary form__item_text text--regular mb-5"
                          >
                            Tiered Per Balance {{ counter + 1 }}
                          </p>
                          <div class="row">
                            <div class="col-6">
                              <label class="form__label"> From </label>
                              <input
                                type="number"
                                placeholder="0.00"
                                class="input form__input form__input--lg"
                                v-model.number="balanceTier.fromTier"
                                min="0.00"
                                readonly
                              />
                            </div>
                            <div class="col-6">
                              <label class="form__label"> To </label>
                              <input
                                type="number"
                                placeholder="0.00"
                                class="input form__input form__input--lg"
                                v-model.number="balanceTier.toTier"
                                min="0.00"
                                readonly
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-6">
                              <div class="form__item">
                                <label class="form__label">
                                  Amount for
                                  {{ deposit.product.interestChargeMethod }}
                                </label>
                                <input
                                  type="number"
                                  placeholder="Enter Amount"
                                  class="input form__input form__input--lg"
                                  v-model.number="balanceTier.interestTierValue"
                                  min="0"
                                  readonly
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div v-show="tierPeriod" class="row">
                      <div class="col-12">
                        <div
                          class="form__item p-5 border border-radius"
                          v-for="(balanceTier, counter) in deposit.product
                            .tiers"
                          :key="counter"
                        >
                          <p
                            class="text--primary form__item_text text--regular mb-5"
                          >
                            Tiered Per Period {{ counter + 1 }}
                          </p>
                          <div class="row">
                            <div class="col-6">
                              <label class="form__label"> From </label>
                              <input
                                type="number"
                                placeholder="0.00"
                                class="input form__input form__input--lg"
                                v-model.number="balanceTier.fromTier"
                                min="0.00"
                                readonly
                              />
                            </div>
                            <div class="col-6">
                              <label class="form__label"> To </label>
                              <input
                                type="number"
                                placeholder="0.00"
                                class="input form__input form__input--lg"
                                v-model.number="balanceTier.toTier"
                                min="0.00"
                                readonly
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-6">
                              <div class="form__item">
                                <label class="form__label">
                                  Amount for
                                  {{ deposit.product.interestChargeMethod }}
                                </label>
                                <input
                                  type="number"
                                  placeholder="Enter Amount"
                                  class="input form__input form__input--lg"
                                  v-model.number="balanceTier.interestTierValue"
                                  min="0"
                                  readonly
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#decriptions"
                  aria-expanded="false"
                  aria-controls="decriptions"
                >
                  <h5
                    class="w-100 mb-0 d-flex align-items-center justify-content-between"
                  >
                    Notes
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div id="decriptions" class="collapse" data-parent="#accordion">
                  <div class="pt-5">
                    <div class="form__item">
                      <textarea
                        cols="5"
                        rows="5"
                        class="input form__input"
                        placeholder="Product Descriptions"
                        v-model.trim="fetchDeposit.savingsAccountNotes"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </ul>

            <div class="form__item">
              <div class="form__action">
                <button
                  class="button form__button form__button--lg w-30 save-changes"
                  v-if="loading"
                >
                  <div class="spinner-border texxt-light" role="status"></div>
                </button>
                <button
                  class="button form__button form__button--lg save-changes"
                  @click.prevent="createDepositAccount"
                  v-else
                >
                  create deposit account
                </button>
                <a
                  data-dismiss="#newDepositModal"
                  role="button"
                  id="closeBtn"
                  class="modal__close form__action__text form__action__text--danger text--capital ml6"
                  @click="closeCreateAccountModal()"
                >
                  cancel
                </a>
              </div>
            </div>
          </div>
        </form>
      </template>
    </createAccount>

    <addAttachment
      v-bind:show="showAttachmentStatus"
      @close="closeAttachmentModal"
    >
      <template v-slot:uploadDocument>
        <form>
          <div class="mb5">
            <div class="form__item">
              <label class="form__label">Title</label>
              <input
                type="text"
                class="input form__input form__input--lg"
                placeholder="Enter Doument title"
                name="title"
                v-model="title"
              />
            </div>
            <div class="form__item">
              <label class="form__label text--regular text--black"
                >Description</label
              >
              <textarea
                cols="5"
                rows="5"
                class="input form__input"
                placeholder="Enter comment"
                name="description"
                v-model="description"
              ></textarea>
            </div>
            <div class="form__item form__item_dottedbg">
              <div class="p-5 text--center position-relative">
                <input
                  id="attachment"
                  type="file"
                  class="input form__input--lg"
                  accept="*"
                  ref="myFiles"
                  @change="onFileChange()"
                  hidden
                />

                <button
                  class="my-2 button button--black text--500 text--white text--small text--capital"
                  @click.prevent="callFileUpload('attachment')"
                >
                  Choose File
                </button>
              </div>
              <ul class="list-group">
                <li class="list-group-item">
                  {{ filename }} ({{ fileSize | kb }})
                </li>
              </ul>
            </div>
          </div>
          <div class="form__item">
            <div class="form__action">
              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--lg save-changes"
                v-if="loading"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                type="button"
                class="button form__button form__button--lg save-changes"
                @click.prevent="uploadAttachment"
                v-else
              >
                Save
              </button>
              <a
                role="button"
                class="modal__close form__action__text form__action__text--danger text--capital ml6"
                @click="closeAttachmentModal"
              >
                cancel
              </a>
            </div>
          </div>
        </form>
      </template>
    </addAttachment>

    <previewModal
      :show="showPreview"
      :isLoading="loading"
      @close="togglePreviewModal"
      :imagePreviewDataName="imagePreviewDataName"
      :imagePreviewDataType="imagePreviewDataType"
    />

    <!-- Attachment -->
    <DeleteModal
      v-bind:show="deleteAttachment"
      @close="closeDeleteAttachmentModal"
    >
      <template v-slot:modal-body>
        <div class="modal__content">
          <div class="modal__body">
            <h5 class="modal__head__title text--capital mb-3">
              Delete Document
            </h5>
            <p>
              Please confirm that you really want to delete this document
              parmanently
            </p>
            <div class="d-flex mt-5">
              <button
                data-dismiss="#confirmModal"
                class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2"
                @click="closeDeleteAttachmentModal"
              >
                cancel
              </button>

              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--lg save-changes fitrem"
                v-if="loading"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--lg save-changes modal__confirm__button--delete fitrem"
                @click="onAttachmentDelete"
                v-else
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </template>
    </DeleteModal>

    <comment v-bind:show="showCommentModal" @close="closeCommentModal">
      <template v-slot:modal-body>
        <form>
          <div class="mb5">
            <div class="form__item">
              <label class="form__label text--regular text--black"
                >Comments</label
              >
              <textarea
                cols="5"
                rows="5"
                class="input form__input"
                placeholder="Enter comment"
                v-model="comment.commentNotes"
              ></textarea>
            </div>
          </div>
          <div class="form__item">
            <div class="form__action">
              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--lg save-changes"
                v-if="loading"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                data-dismiss="#addCommentModal"
                type="button"
                class="button form__button form__button--lg save-changes"
                v-else
                @click="showConfirmComment"
              >
                Save changes
              </button>
              <a
                role="button"
                data-dismiss="#addCommentModal"
                class="modal__close form__action__text form__action__text--danger text--capital ml6"
                @click="closeCommentModal"
              >
                cancel
              </a>
            </div>
          </div>
        </form>
      </template>
    </comment>

    <comment v-bind:show="showEditCommentModal" @close="closeEditCommentModal">
      <template v-slot:modal-head>
        <h5 class="modal__head__title text--capital">Edit Comment</h5>
      </template>
      <template v-slot:modal-body>
        <form>
          <div class="mb5">
            <div class="form__item">
              <label class="form__label text--regular text--black"
                >Update Comment</label
              >
              <textarea
                cols="5"
                rows="5"
                class="input form__input"
                placeholder="Enter comment"
                v-model="editComment.commentNote"
              ></textarea>
            </div>
          </div>
          <div class="form__item">
            <div class="form__action">
              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--lg save-changes"
                v-if="loading"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                data-dismiss="#addCommentModal"
                type="button"
                class="button form__button form__button--lg save-changes"
                v-else
                @click="showUpdateComment"
              >
                Save changes
              </button>
              <a
                role="button"
                data-dismiss="#addCommentModal"
                class="modal__close form__action__text form__action__text--danger text--capital ml6"
                @click="closeEditCommentModal"
              >
                cancel
              </a>
            </div>
          </div>
        </form>
      </template>
    </comment>

    <!-- Add Custome Fields -->
    <addField
      :show="showAddField"
      :editingCustomField="editingCustomField"
      @close="closeAddFieldModal"
    >
      <template v-slot:modal-body v-if="!editingCustomField.isEditing">
        <form>
          <div class="form__item">
            <label class="form__label">Field Set</label>
            <select class="select select--lg" required v-model="customField">
              <option value="" disabled selected>Select Field Set</option>
              <option
                v-for="field in allAccountField"
                :key="field.key"
                :value="field"
              >
                {{ field.customFieldSetName }}
              </option>
            </select>
          </div>
          <div class="form__item">
            <label class="form__label">Field Name</label>
            <select
              class="select select--lg"
              required
              v-model="customFieldKey"
              @change="
                getFieldValueDataType(customField.customFields, customFieldKey)
              "
            >
              <option value="" disabled selected>Select Field Name</option>
              <option
                v-for="cField in customField.customFields"
                :key="cField.id"
                :value="cField.customFieldKey"
              >
                {{ cField.customFieldName }}
              </option>
            </select>
          </div>

          <div v-if="fieldDataType == 'Number'" class="form__item">
            <label class="form__label">Field Value</label>
            <input
              type="number"
              class="input form__input form__input--lg"
              placeholder="Enter Field value"
              required
              v-model="customFieldValue"
              :class="[fieldNumberErr ? 'input-required' : '']"
            />
          </div>
          <div v-if="fieldDataType == 'Checkbox'" class="form__item">
            <label class="form__label">Field Value</label>
            <!-- <div style="display:flex; justify-content:flex-start"> -->
            <input
              type="checkbox"
              id="checkbox"
              class="input form__input form__input--lg"
              placeholder="Enter Field value"
              required
              v-model="customFieldValue"
            />
            <!-- <label class="form__label" for="checkbox">{{ customFieldValue ? "Selected" : "Not Selected" }}</label>
            </div> -->
          </div>
          <div v-if="fieldDataType == 'Free text'" class="form__item">
            <label class="form__label">Field Value</label>
            <input
              type="text"
              class="input form__input form__input--lg"
              placeholder="Enter Field value"
              required
              v-model="customFieldValue"
            />
          </div>
          <div v-if="fieldDataType == 'Date'" class="form__item">
            <label class="form__label">Field Value</label>
            <input
              type="date"
              class="input form__input form__input--lg"
              placeholder="Enter Field value"
              v-model="customFieldValue"
            />
          </div>
          <div v-if="fieldDataType == 'Selection'" class="form__item">
            <label class="form__label">Field Value</label>
            <select
              class="select select--lg"
              required
              v-model="customFieldValue"
            >
              <option value="" disabled selected>Select Field Value</option>
              <option
                v-for="filedValue in selectionFieldValues"
                :key="filedValue.customFieldSelectionKey"
                :value="filedValue.value"
              >
                {{ filedValue.value }}
              </option>
            </select>
          </div>
          <div
            v-if="
              ['customer link', 'group link', 'user link'].includes(
                fieldDataType.toLowerCase()
              )
            "
            class="form__item"
          >
            <label class="form__label">Field Value</label>
            <multiselect
              v-model="linkFieldValue"
              id="ajax"
              label="name"
              track-by="name"
              :placeholder="`Type to search ${fieldDataType.split(' ')[0]}`"
              open-direction="bottom"
              :options="searchValue"
              :multiple="false"
              :searchable="true"
              :internal-search="false"
              :clear-on-select="false"
              :close-on-select="true"
              :options-limit="300"
              :limit="5"
              :max-height="800"
              :show-no-results="false"
              :hide-selected="true"
              @search-change="querySearch"
              @input="assignFieldValue"
            >
              <template slot="tag" slot-scope="{ option, remove }">
                <span class="custom__tag">
                  <span>{{ option.name }}</span>
                  <span class="custom__remove" @click="remove(option)">❌</span>
                </span>
              </template>
            </multiselect>
          </div>
          <!-- <div class="form__item">
            <label class="form__label">Field Value</label>
            <input
              type="text"
              class="input form__input form__input--lg"
              placeholder="Enter Field value"
              required
              v-model="customFieldValue"
            />
          </div> -->

          <div class="form__item">
            <div class="form__action">
              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--lg save-changes"
                v-if="loading"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                data-dismiss="#addFieldModal"
                type="button"
                class="button form__button form__button--lg w-30 save-changes"
                v-else
                @click.prevent="saveCustomField"
              >
                Add Field
              </button>
              <a
                data-dismiss="#addFieldModal"
                role="button"
                class="modal__close form__action__text form__action__text--danger text--capital ml6"
                @click="closeAddFieldModal"
              >
                cancel
              </a>
            </div>
          </div>
        </form>
      </template>
      <template v-slot:modal-body v-else>
        <form>
          <div class="form__item">
            <label class="form__label">Field Set</label>
            <select
              class="select select--lg"
              required
              v-model="editingCustomField.customFieldSetKey"
            >
              <option value="" disabled>Select Field Set</option>
              <option
                v-for="field in allAccountField"
                :key="field.customFieldSetKey"
                :value="field.customFieldSetKey"
                :selected="editingCustomField.customFieldSetKey"
                :disabled="!editingCustomField.customFieldSetKey"
              >
                {{ field.customFieldSetName }}
              </option>
            </select>
          </div>
          <div class="form__item">
            <label class="form__label">Field Name</label>
            <select
              class="select select--lg"
              required
              v-model="editingCustomField.customFieldKey"
            >
              <option value="" disabled selected>Select Field Name</option>
              <option
                v-for="cField in editingCustomField.customField"
                :key="cField.fieldKey"
                :value="cField.fieldKey"
                :selected="editingCustomField.fieldKey"
                :disabled="!editingCustomField.fieldKey"
              >
                {{ cField.fieldName }}
              </option>
            </select>
          </div>

          <div v-if="fieldDataType == 'Number'" class="form__item">
            <label class="form__label">Field Value</label>
            <input
              type="number"
              class="input form__input form__input--lg"
              placeholder="Enter Field value"
              :required="editingCustomField.isRequired"
              v-model="editingCustomField.customFieldValue"
            />
            <span
              class="form__action__text--danger mt-3"
              v-if="customFieldHasError"
              >{{ customFieldError }}</span
            >
          </div>
          <div v-if="fieldDataType == 'Checkbox'" class="form__item">
            <label class="form__label">Field Value</label>
            <!-- <div style="display:flex; justify-content:flex-start"> -->
            <input
              type="checkbox"
              id="checkbox"
              class="input form__input form__input--lg"
              placeholder="Enter Field value"
              required
              v-model="editingCustomField.customFieldValue"
            />
            <!-- <label class="form__label" for="checkbox">{{ editingCustomField.customFieldValue ? "Selected" : "Not Selected" }}</label>
            </div> -->
            <span
              class="form__action__text--danger mt-3"
              v-if="customFieldHasError"
              >{{ customFieldError }}</span
            >
          </div>
          <div v-if="fieldDataType == 'Free text'" class="form__item">
            <label class="form__label">Field Value</label>
            <input
              type="text"
              class="input form__input form__input--lg"
              placeholder="Enter Field value"
              :required="editingCustomField.isRequired"
              v-model="editingCustomField.customFieldValue"
            />
            <span
              class="form__action__text--danger mt-3"
              v-if="customFieldHasError"
              >{{ customFieldError }}</span
            >
          </div>
          <div v-if="fieldDataType == 'Date'" class="form__item">
            <label class="form__label">Field Value</label>
            <input
              type="date"
              class="input form__input form__input--lg"
              placeholder="Enter Field value"
              :required="editingCustomField.isRequired"
              v-model="editingCustomField.customFieldValue"
            />
            <span
              class="form__action__text--danger mt-3"
              v-if="customFieldHasError"
              >{{ customFieldError }}</span
            >
          </div>
          <div v-if="fieldDataType == 'Selection'" class="form__item">
            <label class="form__label">Field Value</label>
            <select
              class="select select--lg"
              :required="editingCustomField.isRequired"
              v-model="editingCustomField.customFieldValue"
            >
              <option value="" disabled selected>Select Field Value</option>
              <option
                v-for="filedValue in selectionFieldValues"
                :key="filedValue.customFieldSelectionKey"
                :value="filedValue.value"
              >
                {{ filedValue.value }}
              </option>
            </select>
            <span
              class="form__action__text--danger mt-3"
              v-if="customFieldHasError"
              >{{ customFieldError }}</span
            >
          </div>
          <div
            v-if="
              ['customer link', 'group link', 'user link'].includes(
                fieldDataType.toLowerCase()
              )
            "
            class="form__item"
          >
            <label class="form__label">Field Value</label>
            <multiselect
              v-model="linkFieldValue"
              id="ajax"
              label="name"
              track-by="name"
              :placeholder="`Type to search ${fieldDataType.split(' ')[0]}`"
              open-direction="bottom"
              :options="searchValue"
              :multiple="false"
              :searchable="true"
              :internal-search="false"
              :clear-on-select="false"
              :close-on-select="true"
              :options-limit="300"
              :limit="5"
              :max-height="800"
              :show-no-results="false"
              :hide-selected="true"
              @search-change="querySearch"
              @input="editAssignFieldValue"
            >
              <template slot="tag" slot-scope="{ option, remove }">
                <span class="custom__tag">
                  <span>{{ option.name }}</span>
                  <span class="custom__remove" @click="remove(option)">❌</span>
                </span>
              </template>
            </multiselect>
            <span
              class="form__action__text--danger mt-3"
              v-if="customFieldHasError"
              >{{ customFieldError }}</span
            >
          </div>

          <div class="form__item">
            <div class="form__action">
              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--lg save-changes"
                v-if="loading"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                data-dismiss="#addFieldModal"
                type="button"
                class="button form__button form__button--lg w-30 save-changes"
                v-else
                @click.prevent="updateCustomField"
              >
                Update Field
              </button>
              <a
                data-dismiss="#addFieldModal"
                role="button"
                class="modal__close form__action__text form__action__text--danger text--capital ml6"
                @click="closeAddFieldModal"
              >
                cancel
              </a>
            </div>
          </div>
        </form>
      </template>
    </addField>

    <DeleteModal v-bind:show="showConfirmSave" @close="closeConfirmSave">
      <template v-slot:modal-body>
        <div class="modal__content">
          <div class="modal__body">
            <h5 class="modal__head__title text--capital mb-3">
              Are you sure you want to save this comment?
            </h5>
            <div class="d-flex mt-5">
              <button
                data-dismiss="#confirmModal"
                class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2"
                @click="closeConfirmSave"
              >
                cancel
              </button>

              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--lg save-changes fitrem"
                v-if="loading"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--lg save-changes fitrem"
                @click="createComment"
                v-else
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </template>
    </DeleteModal>

    <DeleteModal
      v-bind:show="showUpdateCommentModal"
      @close="closeUpdateComment"
    >
      <template v-slot:modal-body>
        <div class="modal__content">
          <div class="modal__body">
            <h5 class="modal__head__title text--capital mb-3">
              Are you sure you want to update this comment?
            </h5>
            <div class="d-flex mt-5">
              <button
                data-dismiss="#confirmModal"
                class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2"
                @click="closeUpdateComment"
              >
                cancel
              </button>

              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--lg save-changes fitrem"
                v-if="loading"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--lg save-changes fitrem"
                @click="onUpdate"
                v-else
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </template>
    </DeleteModal>

    <DeleteModal
      v-bind:show="showDeleteModal"
      v-bind:close="closeDeleteModal"
      @close="closeDeleteModal"
    >
      <template v-slot:modal-body>
        <div class="modal__content">
          <div class="modal__body">
            <h5 class="modal__head__title text--capital mb-3">
              Are you sure you want to delete this comment?
            </h5>
            <div class="d-flex mt-5">
              <button
                data-dismiss="#confirmModal"
                class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2"
                @click="closeDeleteModal"
              >
                cancel
              </button>
              <button
                data-dismiss="#confirmModal"
                class="button button--lg modal__confirm__button modal__confirm__button--delete ml-2 save-changes"
                v-if="loading"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                data-dismiss="#confirmModal"
                class="button button--lg modal__confirm__button modal__confirm__button--delete ml-2 save-changes"
                @click="deleteComment()"
                v-else
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </template>
    </DeleteModal>
    <!-- <CreateLoanAccount
      :show="loanModal"
      :group="groupInfo"
      @close="closeCreateLoanAccountModal" -->
    <EditGroup
      v-bind:showEdit="showEditModal"
      @close="closeEditModal"
      @alertSuccess="alertSuccess"
      @alertError="alertError"
      @refresh="getGroupDetails"
      @alertWarning="alertWarning"
      v-bind:groupFo="editGroupInfo"
    />
  </div>
</template>
<script>
import GroupAside from "@/view/pages/clients/groups/group_aside.vue";
import TaskView from "@/view/pages/tasks/tasks.vue";
import taskService from "@/core/services/general-setup-service/task.service";
import createAccount from "@/components/deposit/_base-createAccount.vue";
import EditGroup from "@/view/pages/clients/groups/EditGroup.vue";
import otherService from "@/core/services/other-service/other.service";
import addField from "@/components/deposit/_base-addFieldModal.vue";
// import groupService from "@/core/services/general-setup-service/group.service";
// import CreateLoanAccount from "../../loans/short_loan_create.vue";
import router from "@/router.js";
import moment from "moment";
import DeleteModal from "@/components/DeleteModal/_base-delete-modal.vue";
import Multiselect from "vue-multiselect";
import { mapState } from "vuex";
import { Role } from "@/core/services/role.js";
import addAttachment from "@/components/deposit/_base-attachment.vue";
import comment from "@/components/deposit/_base-comment.vue";
import ApiService from "@/core/services/api.service";
import GeneralApiService from "@/core/services/general.service";
import AccessApiService from "@/core/services/access.service";

export default {
  name: "group_overview",
  components: {
    Multiselect,
    createAccount,
    // CreateLoanAccount,
    addField,
    GroupAside,
    TaskView,
    EditGroup,
    DeleteModal,
    comment,
    addAttachment,
    previewModal: () =>
      import(
        /* WebpackChunkName: "previewModal" */ "@/components/deposit/preview/preview.vue"
      ),
  },
  data: function () {
    return {
      currentTab: "OverViewTab", // Tabs: OverViewTab, TaskViewTab
      groupInfo: {
        groupKey: null,
        assignedBranchKey: null,
        branchName: null,
        assignedCentreKey: null,
        centreName: null,
        assignedUserKey: null,
        userName: null,
        clientRoleKey: null,
        groupEmailAddress: null,
        groupName: null,
        groupPhoneNumber: null,
        uniqueGroupId: null,
        loanCycle: 0,
        groupMobileNumber: null,
        groupNotes: null,
        creationDate: null,
        lastModifiedDate: null,
        createdBy: null,
        groupMembersRoles: [],
        customFieldSetFieldsAndValue: [],
      },
      groupKey: null,
      isLoading: false,
      groupErrorCode: "",
      groupError: "",
      alert: {
        show: false,
        status: "Success",
        title: "Success",
        description: "",
      },
      attachments: [],
      showPreview: false,
      imagePreviewDataName: null,
      imagePreviewDataType: null,
      attachmentName: "",
      attachmentType: "",
      deleteAttachment: false,
      documentKey: "",
      showAttachmentStatus: false,
      filename: "",
      fileSize: "",
      myFiles: null,
      description: "",
      title: "",
      showCommentModal: false,
      comment: {
        commentNotes: "",
      },
      editComment: [],
      updateComment: {
        commentKey: "",
        commentSource: "Group",
        commentNotes: "",
      },
      comments: [],
      showConfirmSave: false,
      showEditCommentModal: false,
      showUpdateCommentModal: false,
      showDeleteModal: false,
      deleteCommentKey: "",
      loading: false,
      allActivities: [],
      isSaving: false,
      assignedPerson: null,
      users: [],
      taskLinkName: null,
      taskForm: {
        assignedUserKey: null,
        createdByUserKey: null,
        taskDescription: null,
        taskTitle: null,
        // uniqueTaskId: null,
        taskLinkKey: null,
        taskLinkType: "Group",
        dueDate: null,
      },
      taskFormErr: {
        assignedUserKey: false,
        createdByUserKey: false,
        taskDescription: false,
        taskTitle: false,
        // uniqueTaskId: false,
        taskLinkKey: false,
        taskLinkType: false,
        dueDate: false,
      },
      loanModal: false,
      currentDate: moment().format("YYYY-MM-DD"),
      showCreateAccountModal: false,
      depositForm: true,
      nextDepositForm: false,
      deposits: [],
      deposit: {
        accountType: "Groups",
        accountName: "",
        product: [],
        productName: "",
        customerId: "",
      },
      fetchProduct: {
        customerId: "",
        branchId: "",
      },
      selectedCustomer: "",
      fetchDeposit: {
        accountHolderKey: "",
        accountHolderType: "",
        accountType: "",
        assignedBranchKey: "",
        assignedCenterKey: "",
        assignedUserKey: "",
        currencyCode: "",
        interestSettingsKey: "",
        savingsAccountName: "",
        savingsAccountNotes: "",
        savingsProductKey: "",
        interestRate: "",
        maxWithdrawalAmount: "",
        defaultDepositAmount: "",
        uniqueId: "",
        savingsGoalTarget: 0,
      },
      errors: [],
      tierBalance: false,
      tierPeriod: false,
      fixedTermR: false,
      showEditModal: false,
      editGroupInfo: {},
      showAddField: false,
      editingCustomField: {
        customFieldSetKey: "",
        customFieldKey: "",
        customFieldValue: "",
        customField: [],
        isEditing: false,
        isRequired: false,
        isDefault: false,
      },
      allAccountField: [],
      allGroupCustomFields: [],
      customFieldError: "",
      customField: [],
      customFieldKey: "",
      customFieldValue: "",
      field: {
        customFieldSetType: "Customers",
        entityLinkedKey: "",
      },
      fieldDataType: "",
      selectionFieldValues: [],
      searchValue: [],
      linkFieldValue: "",
      fieldNumberErr: false,
      openingBalanceErr: false,
      getAssignedBranchKey: "",
      getAssignedUserKey: "",
      customerLoanDeposits: [],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    userIsUnauthorized() {
      return this.groupErrorCode == 409;
    },
    userCanUploadAttachment() {
      const document = this.user.scopes.find(
        (item) => item.scopeName === Role.Documents
      );
      const { permissionsList } = document;
      return permissionsList.CanCreateDocuments;
    },
    userCanEditAttachment() {
      const document = this.user.scopes.find(
        (item) => item.scopeName === Role.Documents
      );
      const { permissionsList } = document;
      return permissionsList.CanEditDocuments;
    },
    userCanEditGroup() {
      const document = this.user.scopes.find(
        (item) => item.scopeName === Role.Group
      );
      const { permissionsList } = document;
      return permissionsList.CanEditGroup;
    },
    userCanViewGroup() {
      const document = this.user.scopes.find(
        (item) => item.scopeName === Role.Group
      );
      const { permissionsList } = document;
      return permissionsList.CanViewGroup;
    },
    userCanCreateGroup() {
      const group = this.user.scopes.find(
        (item) => item.scopeName === Role.Group
      );
      const { permissionsList } = group;
      return permissionsList.CanCreateGroup;
    },
    userCanCreateTask() {
      const task = this.user.scopes.find(
        (item) => item.scopeName === Role.Tasks
      );
      const { permissionsList } = task;
      return permissionsList.CanCreateTask;
    },
    userCanCreateDeposit() {
      const deposit = this.user.scopes.find(
        (item) => item.scopeName === Role.DepositAccount
      );
      const { permissionsList } = deposit;
      return permissionsList.CanCreateSavingsAccount;
    },
    userCanCreateCustomField() {
      const deposit = this.user.scopes.find(
        (item) => item.scopeName === Role.CustomField
      );
      const { permissionsList } = deposit;
      return permissionsList.CanCreateCustomField;
    },
    userCanEditCustomField() {
      const deposit = this.user.scopes.find(
        (item) => item.scopeName === Role.CustomField
      );
      const { permissionsList } = deposit;
      return permissionsList.CanEditCustomField;
    },
    userCanViewCustomField() {
      const deposit = this.user.scopes.find(
        (item) => item.scopeName === Role.CustomField
      );
      const { permissionsList } = deposit;
      return permissionsList.CanViewCustomField;
    },
    userCanViewComment() {
      const comment = this.user.scopes.find(
        (item) => item.scopeName === Role.Comments
      );
      const { permissionsList } = comment;
      return permissionsList.CanViewComments;
    },
    userCanCreateComment() {
      const comment = this.user.scopes.find(
        (item) => item.scopeName === Role.Comments
      );
      const { permissionsList } = comment;
      return permissionsList.CanCreateComments;
    },
    userCanEditComment() {
      const comment = this.user.scopes.find(
        (item) => item.scopeName === Role.Comments
      );
      const { permissionsList } = comment;
      return permissionsList.CanEditComments;
    },
    userCanDeleteComment() {
      const comment = this.user.scopes.find(
        (item) => item.scopeName === Role.Comments
      );
      const { permissionsList } = comment;
      return permissionsList.CanDeleteComments;
    },
    isSavingPlan() {
      return (
        this.deposit.product.productType === "Saving plan" ||
        this.deposit.product.productType === "Savings plan" ||
        this.deposit.product.productType === "SAVING PLAN" ||
        this.deposit.product.productType === "SAVINGPLAN"
      );
    },
  },
  methods: {
    openAddFieldModal() {
      this.showAddField = true;
      this.fieldDataType = "";
    },
    closeAddFieldModal() {
      this.showAddField = false;
      this.searchValue = [];
      this.resetCustomField();
      this.editingCustomField.editingCustomField = false;
    },
    getFieldValueDataType(data, key) {
      this.customFieldValue = "";
      const getField = data.find((field) => field.customFieldKey == key);
      this.fieldDataType = getField.dataType;

      if (getField.dataType == "Selection") {
        this.selectionFieldValues = getField.customFieldSelections;
      }
      if (getField.dataType == "Checkbox") {
        this.customFieldValue = false;
      }
    },
    validateNumber(num) {
      if (isNaN(parseFloat(num))) {
        this.fieldNumberErr = true;
        return true;
      } else {
        this.fieldNumberErr = false;
        return false;
      }
    },
    async querySearch(query) {
      let searchQuery = query;
      this.searchValue = [];
      let searchArea =
        this.fieldDataType.toLowerCase() == "customer link"
          ? "customer"
          : this.fieldDataType.toLowerCase() == "group link"
          ? "group"
          : "user";

      try {
        const response = await ApiService.post(`GlobalSearch`, {
          searchQuery,
          searchCategory: searchArea,
        });
        this.searchValue = response.data.data;
      } catch (e) {
        this.searchValue = [];
      }
    },
    assignFieldValue(value) {
      this.customFieldValue = value.linkKey;
    },
    editAssignFieldValue(value) {
      this.editingCustomField.customFieldValue = value.linkKey;
    },
    getAccountField() {
      this.allAccountField = [];
      GeneralApiService.get("Group/GetAllCustomFieldsForGroup")
        .then((response) => {
          this.allAccountField = response.data.data.items;
        })
        .catch((error) => {
          this.alertError(error.response.data.message);
        });
    },
    // getAccountField() {
    //   this.allAccountField = [];
    //   this.field.entityLinkedKey = this.$route.params.id;
    //   ApiService.post(
    //     "CustomFields/FieldSets/FieldSetType",
    //     this.field
    //   )
    //     .then((response) => {
    //       this.allAccountField = response.data.data.items;
    //     })
    //     .catch((error) => {
    //       this.alertError(error.response.data.message);
    //     });
    // },
    saveCustomField() {
      if (this.fieldDataType == "Number") {
        if (this.validateNumber(this.customFieldValue)) {
          this.alertWarning("Invalid input type, number required.");
          return;
        }
      }

      this.loading = true;
      let formData = {
        customerKey: this.groupKey,
        values: [
          {
            customFieldKey: this.customFieldKey,
            customFieldValue: this.customFieldValue,
          },
        ],
      };
      GeneralApiService.post("Group/AdSetCustomFieldValue", formData)
        .then(() => {
          this.loading = false;
          this.alertSuccess("Successfully added field");
          this.closeAddFieldModal();
          this.getGroupDetails();
          this.resetCustomField();
        })
        .catch((error) => {
          this.loading = false;
          this.alertError(error.response.data.message);
        });
    },
    updateCustomField() {
      this.checkCustomFieldError();
      if (this.fieldDataType == "Number") {
        if (this.validateNumber(this.editingCustomField.customFieldValue)) {
          this.alertWarning("Invalid input type, number required.");
          return;
        }
      }

      if (!this.customFieldError) {
        this.loading = true;
        let formData = {
          customerKey: this.groupKey,
          values: [
            {
              customFieldKey: this.editingCustomField.customFieldKey,
              customFieldValue: this.editingCustomField.customFieldValue,
            },
          ],
        };
        GeneralApiService.post("Group/AdSetCustomFieldValue", formData)
          .then(() => {
            this.loading = false;
            this.alertSuccess("Custom field updated successfully");
            this.closeAddFieldModal();
            this.getGroupDetails();
            this.resetCustomField();
          })
          .catch((error) => {
            this.loading = false;
            this.alertError(error.response.data.message);
          });
      }
    },
    resetCustomField() {
      this.customField = [];
      this.editingCustomField = {
        customFieldSetKey: "",
        customFieldKey: "",
        customFieldValue: "",
        customField: "",
        isEditing: false,
        isRequired: false,
        isDefault: false,
      };
      this.editingCustomField.editingCustomField = false;
      this.customFieldValue = "";
      this.customFieldKey = "";
    },
    customFieldHasError() {
      return this.customFieldError !== null;
    },
    editCustomField(key, keySub, val, req, def, data, isEditing) {
      const getField = data.find((field) => field.fieldKey == keySub);
      this.fieldDataType = getField.dataType;

      if (getField.dataType == "Selection") {
        const getFieldData = this.allAccountField.find(
          (field) => field.customFieldSetKey == key
        );
        const getSelectedField = getFieldData.customFields.find(
          (field) => field.customFieldKey == keySub
        );
        this.selectionFieldValues = getSelectedField.customFieldSelections;
      }
      if (
        ["customer link", "group link", "user link"].includes(
          this.fieldDataType.toLowerCase()
        )
      ) {
        const linkKey = getField.value.length
          ? getField.value.split("-").at(-2)
          : "";
        const linkName = getField.value.length
          ? getField.value.split("-").at(-1)
          : "";
        val = linkKey;
        this.linkFieldValue = {};
        this.linkFieldValue = {
          ...this.linkFieldValue,
          name: linkName,
          linkKey: linkKey,
        };
        this.querySearch(linkName.split(" ")[0]);
      }
      this.editingCustomField.customFieldSetKey = key;
      this.editingCustomField.customFieldKey = keySub;
      this.editingCustomField.customFieldValue = val;
      this.editingCustomField.customField = data;
      this.editingCustomField.isEditing = isEditing;
      this.editingCustomField.isRequired = req;
      this.editingCustomField.isDefault = def;
      this.showAddField = true;
      // this.openAddFieldModal();
    },
    routeToLinkView(key, type) {
      const link = key.split("-").at(-2);
      if (type.toLowerCase() == "customer link") {
        this.$router.push({ path: `/customer/${link}` });
      }
      if (type.toLowerCase() == "group link") {
        this.$router.push({ path: `/group/${link}` });
      }
      if (type.toLowerCase() == "user link") {
        this.$router.push({ path: `/user/${link}` });
      }
    },
    checkCustomFieldError() {
      if (
        this.editingCustomField.isRequired &&
        this.editingCustomField.customFieldValue === ""
      ) {
        this.customFieldError = "This field is required";
        return;
      }
      this.customFieldError = "";
    },
    saveTaskForm: async function (e) {
      e.preventDefault();
      if (this.validateTaskForm()) {
        try {
          this.isSaving = true;
          this.taskForm.assignedUserKey = this.assignedPerson.userKey;
          this.taskForm.taskLinkKey = this.groupInfo.groupKey;
          await taskService.create(this.taskForm);
          this.isSaving = false;
          document.getElementById("newTaskModalClose").click();
          this.clearTaskForm();
          this.assignedPerson = "";
          this.alertSuccess("Task added.");
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.message;
          this.alertError(error);
        }
      } else {
        this.alertWarning("Some form fields are required.");
      }
    },
    getUsers() {
      const page = {
        pageNumber: 1,
        pageSize: 10000,
        status: true,
        filters: [],
      };
      AccessApiService.post("ProleanUser/GetUsersWithoutPermission", page).then(
        (response) => {
          this.users = response.data.data;
        }
      );
    },
    limitText(count) {
      return `and ${count} other customers`;
    },
    name({ name }) {
      return `${name}`;
    },
    validateTaskForm() {
      this.clearTaskValidation();
      if (
        this.taskForm.taskTitle &&
        this.taskForm.dueDate
        // &&
        // this.taskForm.uniqueTaskId &&
        // this.taskForm.assignedUserKey
      ) {
        return true;
      }

      if (!this.taskForm.taskTitle) {
        this.taskFormErr.taskTitle = true;
      }
      if (!this.taskForm.dueDate) {
        this.taskFormErr.dueDate = true;
      }
      // if (!this.taskForm.uniqueTaskId) {
      //   this.taskFormErr.uniqueTaskId = true;
      // }
      if (!this.taskForm.assignedUserKey) {
        this.taskFormErr.assignedUserKey = true;
      }
    },
    // openCreateLoanAccountModal() {
    //   this.loanModal = true;
    // },
    // closeCreateLoanAccountModal() {
    //   this.loanModal = false;
    // },
    clearTaskForm() {
      this.taskForm = {
        assignedUserKey: null,
        createdByUserKey: null,
        taskDescription: null,
        taskTitle: null,
        // uniqueTaskId: null,
        taskLinkKey: this.groupInfo.groupKey,
        taskLinkType: "Group",
        dueDate: null,
      };
    },
    clearTaskValidation() {
      this.taskFormErr = {
        assignedUserKey: false,
        createdByUserKey: false,
        taskDescription: false,
        taskTitle: false,
        // uniqueTaskId: false,
        taskLinkKey: false,
        taskLinkType: false,
        dueDate: false,
      };
    },
    openCreateAccountModal() {
      this.showCreateAccountModal = true;
      this.getCustomerId();
    },
    closeCreateAccountModal() {
      this.showCreateAccountModal = false;
      this.nextDepositForm = false;
      this.depositForm = true;
      this.deposit.accountType = "";
      this.deposit.accountName = "";
      this.deposit.product = [];
      this.resetDepositForm();
      this.resetFetchForm();
      document.getElementById("closeBtn").click();
    },
    depositAccountFormCreate() {
      this.depositForm = false;
      this.nextDepositForm = true;

      if (this.deposit.product.interestRateTerms === "Fixed") {
        this.fixedTermR = true;
      } else if (
        this.deposit.product.interestRateTerms === "Tier per balance" ||
        this.deposit.product.interestRateTerms === "Tier Per Balance"
      ) {
        this.tierBalance = true;
      } else if (
        this.deposit.product.interestRateTerms === "Tier per period" ||
        this.deposit.product.interestRateTerms === "Tier Per Period"
      ) {
        this.tierPeriod = true;
      } else {
        this.fixedTermR = false;
        this.tierBalance = false;
        this.tierPeriod = false;
      }
      this.fetchDeposit.accountHolderKey = this.groupInfo.groupKey;
      this.fetchDeposit.accountHolderType = this.deposit.accountType;
      this.fetchDeposit.accountType = this.deposit.product.productType;
      this.fetchDeposit.assignedBranchKey = this.groupInfo.assignedBranchKey;
      this.fetchDeposit.assignedCenterKey = this.groupInfo.assignedCentreKey;
      this.fetchDeposit.assignedUserKey = this.groupInfo.assignedUserKey;
      this.fetchDeposit.currencyCode = this.deposit.product.currency;
      this.fetchDeposit.interestSettingsKey =
        this.deposit.product.interestSavingsKey;
      this.fetchDeposit.savingsAccountName = this.deposit.product.productName;
      this.fetchDeposit.savingsProductKey = this.deposit.product.id;
      this.fetchDeposit.maxWithdrawalAmount =
        this.deposit.product.maxWithdrawalAmount;
      this.fetchDeposit.defaultDepositAmount =
        this.deposit.product.defaultDepositAmount;
    },
    getCustomerId() {
      this.deposits = [];
      this.deposit.customerId = this.groupKey;
      this.fetchDeposit.accountHolderKey = this.groupInfo.groupKey;
      this.fetchProduct.customerId = this.groupKey;
      this.fetchProduct.branchId = this.groupInfo.assignedBranchKey;
      ApiService.post(
        "DepositAdministration/FetchDepositProductsByCustomerOrGroup",
        this.fetchProduct
      )
        .then((resp) => {
          this.deposits = resp.data.data;
        })
        .catch((error) => {
          this.alertError(error.response.data.message);
        });
    },
    resetDepositForm() {
      this.deposit = {
        accountType: "Groups",
        accountName: "",
        customerId: "",
        productName: "",
        product: [],
      };
      this.selectedCustomer = {};
    },
    resetFetchForm() {
      this.fetchDeposit = {
        accountHolderKey: "",
        accountHolderType: "",
        accountType: "",
        assignedBranchKey: "",
        assignedCenterKey: "",
        assignedUserKey: "",
        currencyCode: "",
        interestSettingsKey: "",
        savingsAccountName: "",
        savingsAccountNotes: "",
        savingsProductKey: "",
        interestRate: "",
        maxWithdrawalAmount: "",
        defaultDepositAmount: "",
        savingsGoalTarget: 0,
        uniqueId: "",
      };
    },
    createDepositAccount(e) {
      this.fetchDeposit.uniqueId = this.deposit.product.sampleIdGenerated;
      if (this.fetchDeposit.accountType !== "Fixed Deposit") {
        this.loading = true;
        ApiService.post(
          "DepositAccount/CreateDepositAccount",
          this.fetchDeposit
        )
          .then((resp) => {
            this.loading = false;
            this.alertSuccess(resp.data.message);
            this.closeCreateAccountModal();
            this.viewRoute(resp.data.message);
          })
          .catch((e) => {
            this.loading = false;
            this.alertError(e.response.data.message);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.errors = [];
        if (
          this.fetchDeposit.interestRate <
            this.deposit.product.minInterestRateConstraints ||
          this.fetchDeposit.interestRate >
            this.deposit.product.maxInterestRateConstraints
        ) {
          this.alertError(
            `Interest rate must be between ${this.deposit.product.minInterestRateConstraints} and ${this.deposit.product.maxInterestRateConstraints}`
          );
          this.errors.push(
            `Interest must be between ${this.deposit.product.minInterestRateConstraints} and ${this.deposit.product.maxInterestRateConstraints}`
          );
        }
        if (!this.errors.length) {
          this.loading = true;
          ApiService.post(
            "DepositAccount/CreateDepositAccount",
            this.fetchDeposit
          )
            .then((resp) => {
              this.loading = false;
              this.alertSuccess(resp.data.message);
              this.closeCreateAccountModal();
              this.$router.push({
                path: `/deposit-account/${resp.data.message}`,
              });
            })
            .catch((e) => {
              this.loading = false;
              this.alertError(e.response.data.message);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      }
      e.preventDefault();
    },
    viewRoute(data) {
      this.$router.push({ path: `/deposit-account/${data}` });
      // this.$router.push({ path: `/deposit-account/${data}` }, () =>
      //   window.location.reload()
      // );
    },
    async openEditModal() {
      this.showEditModal = true;
      this.editGroupInfo = {};
      this.editGroupInfo = this.groupInfo;
      await this.editGroupInfo.customFieldSetFieldsAndValue.forEach(
        async (item) => {
          const getAccount = this.allAccountField.find(
            (acc) => acc.customFieldSetKey == item.fieldSetKey
          );
          for (let i = 0; i < item.fields.length; i++) {
            if (item.fields[i].dataType == "Selection") {
              item.fields[i].selectionFieldValues =
                getAccount.customFields.find(
                  (field) => field.customFieldKey == item.fields[i].fieldKey
                ).customFieldSelections;
            }

            if (
              ["customer link", "group link", "user link"].includes(
                item.fields[i].dataType.toLowerCase()
              )
            ) {
              let searchArea =
                item.fields[i].dataType.toLowerCase() == "customer link"
                  ? "customer"
                  : item.fields[i].dataType.toLowerCase() == "group link"
                  ? "group"
                  : "user";

              let linkName = item.fields[i]?.value.length
                ? item.fields[i].value.split("-").at(-1)
                : "";
              let linkKey = item.fields[i]?.value.length
                ? item.fields[i].value.split("-").at(-2)
                : "";
              let searchQuery = item.fields[i].linkName
                ? item.fields[i].linkName.split(" ")[0]
                : "";

              try {
                const response = await ApiService.post(`GlobalSearch`, {
                  searchQuery,
                  searchCategory: searchArea,
                });
                item.fields[i].searchValue = response.data.data
                  ? response.data.data
                  : [];
                item.fields[i].value = {};
                if (linkName && linkKey) {
                  item.fields[i].value = {
                    ...item.fields[i].value,
                    name: linkName,
                    linkKey: linkKey,
                  };
                } else {
                  item.fields[i].value = null;
                }
              } catch (e) {
                item.fields[i].searchValue = [];
              }
            }
          }
        }
      );
    },
    closeEditModal() {
      GeneralApiService.get(`Group/${this.groupKey}`).then((resp) => {
        this.groupInfo = resp.data.data;
      });
      this.showEditModal = false;
    },
    switchTab(tabName) {
      this.currentTab = tabName;
    },
    goToDashboard() {
      this.$router.push({ path: "/dashboard" });
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertWarning(description) {
      this.alert.status = "warn";
      this.alert.title = "Warning";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    imageAlert({ type, message }) {
      if (type === "error") {
        this.alertError(message);
      }

      if (type === "success") {
        this.alertSuccess(message);
      }
    },
    openAttachmentModal() {
      this.showAttachmentStatus = true;
    },
    closeAttachmentModal() {
      this.showAttachmentStatus = false;
    },
    onFileChange() {
      this.myFiles = this.$refs.myFiles.files[0];
      this.filename = this.$refs.myFiles.files[0].name;
      this.fileSize = this.$refs.myFiles.files[0].size;
    },
    callFileUpload(id) {
      let input = document.getElementById(id);
      input.click();
    },
    getActivities() {
      this.allActivities = [];
      ApiService.get(
        `DepositAccount/GetActivityByCustomerGroupKey?groupKey=${this.groupKey}`
      )
        .then((response) => {
          this.allActivities = response.data.data;
        })
        .catch((error) => {
          this.alertError(error);
        });
    },
    uploadAttachment() {
      const formData = new FormData();
      formData.append("file", this.myFiles);
      formData.append("documentTitle", this.title);
      formData.append("description", this.description);
      this.loading = true;
      GeneralApiService.postFormData(
        `DocumentAttachment/UploadAttachment/${this.groupKey}?accountType=Group&documentTitle=${this.title}`,
        formData
      )
        .then(() => {
          let desc = "File successully uploaded";
          this.alertSuccess(desc);
          this.loading = false;
          this.getActivities();
          this.getAttachment();
          this.closeAttachmentModal();
          // this.getGroupDetails();
          // this.getComment();
          this.filename = "";
          this.fileSize = "";
          this.title = "";
          this.description = "";
        })
        .catch((error) => {
          this.alertError(error.response.data.message);
          this.loading = false;
        });
    },
    togglePreviewModal() {
      this.showPreview = !this.showPreview;
    },
    previewFile(key) {
      this.loading = true;
      this.showPreview = !this.showPreview;
      ApiService.get(`GeneralDocument/PreviewDocument?documentKey=${key}`)
        .then((response) => {
          this.loading = false;
          this.imagePreviewDataType = response.data.data.fileType;
          this.imagePreviewDataName = response.data.data.fileUrl;
        })
        .catch((error) => {
          this.loading = false;
          this.alertError(error.response.data.message);
        });
    },
    async downloadFile(key) {
      await this.getAttachmentName(key);
      ApiService.getFormData(
        `GeneralDocument/DownloadDocument?documentKey=${key}`
      )
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", this.attachmentName || "file");
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch((error) => {
          this.alertError(error.response.data.message);
        });
    },
    getAttachmentName(key) {
      this.attachmentName = "";
      ApiService.get(
        `GeneralDocument/getUploadedDocumentByDocumentkey?documentKey=${key}`
      )
        .then((response) => {
          this.attachmentName = response.data.data.document_original_file_name;
          this.attachmentType = response.data.data.document_type;
        })
        .catch((error) => {
          this.alertError(error.response.data.message);
        });
    },
    openDeleteAttachmentModal(del) {
      this.deleteAttachment = true;
      this.documentKey = del;
    },
    closeDeleteAttachmentModal() {
      this.deleteAttachment = false;
    },
    onAttachmentDelete() {
      this.loading = true;
      ApiService.delete(
        `GeneralDocument/delete?documentKey=${this.documentKey}`
      )
        .then((response) => {
          this.alertSuccess(response.data.message);
          this.loading = false;
          this.getActivities();
          this.getAttachment();
          this.closeDeleteAttachmentModal();
        })
        .catch((error) => {
          this.alertError(error.response.data.message);
        });
    },
    getAttachment() {
      this.attachments = [];
      ApiService.get(
        `GeneralDocument/getUploadedDocumentByHolderkey?holderkey=${this.$route.params.id}&pageIndex=1&pageSize=10`
      )
        .then((resp) => {
          this.attachments = resp.data.data;
        })
        .catch((error) => {
          this.alertError(error.response.data.message);
        });
    },
    openCommentModal() {
      this.showCommentModal = true;
    },
    closeCommentModal() {
      this.showCommentModal = false;
    },
    closeEditCommentModal() {
      this.showEditCommentModal = false;
    },
    showUpdateComment() {
      this.showUpdateCommentModal = true;
    },
    closeUpdateComment() {
      this.showUpdateCommentModal = false;
    },
    openEditCommentModal(comment) {
      this.showEditCommentModal = true;
      this.editComment = comment;
    },
    openDeleteModal(comment) {
      this.showDeleteModal = true;
      this.deleteCommentKey = comment.commentKey;
    },
    closeDeleteModal() {
      this.showDeleteModal = false;
    },
    deleteComment() {
      let commentKey = this.deleteCommentKey;
      this.loading = true;
      GeneralApiService.delete(`Comment/${commentKey}`)
        .then(() => {
          this.alertSuccess("Comment deleted successfully!");
          this.loading = false;
          this.showDeleteModal = false;
          this.getActivities();
          this.getComment();
        })
        .catch((error) => {
          this.alertError(error);
          this.loading = false;
        });
    },
    onUpdate() {
      this.loading = true;
      this.updateComment.commentKey = this.editComment.commentKey;
      this.updateComment.commentNote = this.editComment.commentNote;
      GeneralApiService.put(
        `Comment/${this.updateComment.commentKey}`,
        this.updateComment
      )
        .then(() => {
          this.loading = false;
          let desc = "Comment succesfully updated!";
          this.alertSuccess(desc);
          this.closeEditCommentModal();
          this.closeUpdateComment();
          this.getActivities();
          this.getGroupDetails();
          this.getComment();
        })
        .catch((error) => {
          this.loading = false;
          this.alertError(error.response.data.message);
          this.closeUpdateComment();
        });
    },
    getComment() {
      this.comments = [];
      GeneralApiService.get(`Comment/${this.groupKey}`)
        .then((resp) => {
          this.comments = resp.data.data.items;
        })
        .catch((error) => {
          const e = error?.response?.data?.message;
          if (e) this.alertError(e);
        });
    },
    resetCommentForm() {
      this.comment = {
        commentNotes: "",
        commentSource: "Group",
      };
    },
    showConfirmComment() {
      this.showConfirmSave = true;
    },
    closeConfirmSave() {
      this.showConfirmSave = false;
    },
    createComment() {
      this.loading = true;
      const data = {
        commentSource: "Group",
        parentKey: this.groupKey,
        commentNote: this.comment.commentNotes,
      };
      GeneralApiService.post(`Comment/Comment`, data)
        .then(() => {
          this.loading = false;
          let desc = "Comment succesfully created!";
          this.alertSuccess(desc);
          this.closeConfirmSave();
          this.closeCommentModal();
          this.getActivities();
          this.getComment();
          this.resetCommentForm();
        })
        .catch((error) => {
          this.loading = false;
          this.alertError(error.response.data.message);
          this.closeConfirmSave();
        });
    },
    async getCustomerLoanDeposits(key) {
      try {
        const res = await otherService.getAllLoanDepositAccount(key);
        this.customerLoanDeposits = res.data.data;
        if (
          this.customerLoanDeposits &&
          this.customerLoanDeposits.closedAccount.length > 0
        ) {
          this.customerLoanDeposits.closedAccount.forEach((item) => {
            this.depositTotal += item.balance;
          });
        }

        if (
          this.customerLoanDeposits &&
          this.customerLoanDeposits.operationalAccount.length > 0
        ) {
          this.customerLoanDeposits.operationalAccount.forEach((item) => {
            this.depositTotal += item.balance;
          });
        }
      } catch (e) {
        this.isLoading = false;
      }
    },
    async getGroupDetails() {
      this.groupKey = this.$route.params.id;
      this.isLoading = true;
      if (this.groupKey) {
        // const res = await groupService.getGroupByKey(this.groupKey);
        GeneralApiService.get(`Group/${this.groupKey}`)
          .then((resp) => {
            this.groupInfo = resp.data.data;
            this.taskLinkName = this.groupInfo.groupName;
            this.isLoading = false;
            this.getCustomerLoanDeposits(this.groupKey);
          })
          .catch((error) => {
            this.isLoading = false;
            this.groupErrorCode = error.response.status;
            this.groupError = error.response.data.message;
          });
      } else {
        router.push({ name: "groups" });
      }
    },
  },
  async created() {
    this.getGroupDetails();
    this.getAccountField();
    // this.getGroupCustomFields();
    this.getActivities();
    this.getAttachment();
    this.getUsers();
    this.getComment();
  },
  filters: {
    formatDatePipe: function (date) {
      return date ? moment(date, "YYYY-MM-DD").format("DD-MM-YYYY") : "";
    },
    kb(val) {
      const sizes = ["bytes", "KB", "MB", "GB"];
      if (val == 0) return "";
      const i = parseInt(Math.floor(Math.log(val) / Math.log(1024)), 10);
      if (i == 0) return `${val} ${sizes[i]}`;
      return `${(val / 1024 ** i).toFixed(1)} ${sizes[i]}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.col,
.col-6 {
  word-break: break-word;
}
</style>
